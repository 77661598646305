.wrapper {
	display: grid;
	grid-gap: 5px;
	grid-template-columns: 110px 110px 110px 110px;
	background-color: #fff;
	color: #444;
}

.gallery {
	height: 100px;
	max-height: 100px;
	width: 100px;
	max-width: 100px;
	cursor: pointer;
	object-fit: cover;
}

.box {
	background-color: lightgrey;
	color: #fff;
	border-radius: 5px;
	padding: 5px;

	margin-left: auto;
	margin-right: auto;
	display: block;
	/* font-size: 150%; */
}

.table {
	margin-bottom: 40px;
}

.rowTable {
	margin-bottom: 20px;
}

.table::after {
	content: "";
	display: block;
	width: 20%;
	/* margin: 1em auto 0; */
	margin-top: 5px;
	border-bottom: solid lightgrey;
	/* float: left; */
}

.rowTable::after {
	content: "";
	display: block;
	width: 20%;
	margin-left: 15px;
	margin-top: 5px;
	border-bottom: solid lightgrey;
	/* float: left; */
}

.react-images__footer {
	display: none !important;
}

.react-images__view-image {
	max-width: 460px !important;
	max-height: 260px !important;
}

.dialogC {
	overflow-y: visible;
}

.grid-container {
	display: grid;
	grid-template-columns: 80px 80px 80px;
  }

  .grid-item{
	  margin-left: 30px;
	  margin-top: 20px;
  }

@media (max-width: 800px) {
	.grid-container {
		grid-template-columns: repeat(1, 1fr);
	  }

	.grid-item{
		margin-left: 0px;
		margin-top: 10px;
	}
  }

.separatedDetailWindow {
	margin-top: 15px;
	margin-bottom: 20px;
	/* float: left; */
	font-size: 18px;
	color: rgb(122, 122, 122);
	clear: both;
}

.separatedDetailWindow::after {
	content: "";
	display: block;
	width: 20%;
	/* margin: 1em auto 0; */
	margin-top: 5px;
	border-bottom: solid lightgrey;
	/* float: left; */
}
